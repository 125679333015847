import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {AddFancytextPanelProps} from '@Panels/add-fancytext-panel/add-fancytext-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'add-fancytext-panel';

export type FancytextModalProps = AddFancytextPanelProps;

export default function FancytextModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.FANCYTEXT_MODAL];
  });
  const [height, setHeight] = useState('587px');
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }
  }, [sizeData]);

  const width = '100%';
  const maxWidth = '944px';

  return (
    <Modal
      modalId={MODALS_IDS.FANCYTEXT_MODAL}
      modalMinHeight="580px"
      modalMaxHeight="701px"
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      modalMaxWidth={maxWidth}
      modalWidth={width}
      isBottomSheetOnMobile
    >
      <LazyPanel
        panelDirectoryName={PANEL_ID}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openFancytextModal = (props: FancytextModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.FANCYTEXT_MODAL,
      panelProps: props,
    })
  );
};
