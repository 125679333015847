import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'timeline-panel';
const PANEL_HEIGHT = 72;
export default function TimelineModal(): ReactElement {
  const [height, setHeight] = useState(`${PANEL_HEIGHT}px`);
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });
  const maxWidth = '800px';

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }
  }, [sizeData]);

  return (
    <Modal
      disableDragToClose
      isBottomSheetNonBlocking
      bottomSheetCloseThreshold={50}
      modalId={MODALS_IDS.TIMELINE_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      isBottomSheetOnMobile
      modalMaxWidth={maxWidth}
      modalWidth="100%"
    >
      <LazyPanel
        panelDirectoryName="timeline-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openTimelineModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.TIMELINE_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};
