import {MODALS_IDS} from '@Components/modal-container';
import useWindowSize from '@Hooks/useWindowSize';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import type {ReactElement} from 'react';
import React from 'react';
import {triggerResumablePanelOpening} from '@Components/modal/modal.library';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {StockModalProps} from '@Modals/stock-modal';
import {useAppSelector} from '@/hooks';

const DEFAULT_PANEL_ID = 'DEFAULT_RESUMABLE_STOCK_PANEL_ID';

interface ResumableStockModalProps extends StockModalProps {
  panelId: string;
}

export default function ResumableStockModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.RESUMABLE_STOCK_MODAL];
  });
  const {windowWidth} = useWindowSize();
  const panelId = `${modalData.panelProps.panelId ?? DEFAULT_PANEL_ID}`;

  const height = '94%';
  let width = '600px';
  if (windowWidth > 2000) {
    width = '1600px';
  } else if (windowWidth <= 2000 && windowWidth > 550) {
    width = '80%';
  }

  return (
    <Modal
      key={`${MODALS_IDS.RESUMABLE_STOCK_MODAL}-${panelId}`}
      modalId={MODALS_IDS.RESUMABLE_STOCK_MODAL}
      stickToBottom
      isBottomSheetOnMobile
      panelId={panelId}
      modalHeight={height}
      modalWidth={width}
    >
      <LazyPanel
        key={panelId}
        panelDirectoryName="stock-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId,
        }}
      />
    </Modal>
  );
}

export const openResumableStockMediaMoal = (props: ResumableStockModalProps) => {
  triggerResumablePanelOpening(props.panelId, () => {
    window.PMW.redux.store.dispatch(
      openPanelInModal({
        modalId: MODALS_IDS.RESUMABLE_STOCK_MODAL,
        panelProps: {...props},
      })
    );
  });
};
