import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {LazyPanel} from '@Components/lazy-panel';
import {useDispatch} from 'react-redux';
import type {ColorBackgroundPanelProps} from '@Panels/color-background-panel/color-background-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'color-background-panel';

export type ColorBackgroundModalProps = ColorBackgroundPanelProps;

export default function ColorBackgroundModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.COLOR_BACKGROUND_MODAL];
  });
  const dispatch = useDispatch();

  const height = '650px';
  const width = '1260px';

  return (
    <Modal modalId={MODALS_IDS.COLOR_BACKGROUND_MODAL} isBottomSheetOnMobile mobileHeight={height} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName={PANEL_ID}
        panelProps={{
          ...modalData.panelProps,
          onApplyChanges: (background: ColorBackgroundPanelProps): void => {
            dispatch(closeModal(MODALS_IDS.COLOR_BACKGROUND_MODAL));
            modalData.panelProps.onApplyChanges(background);
          },
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openColorBackgroundModal = (props: ColorBackgroundModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.COLOR_BACKGROUND_MODAL,
      panelProps: props,
    })
  );
};
